import { Component, HostListener } from '@angular/core'
import anime from 'animejs/lib/anime.es'

import { color_bg } from '../../../data/coordinates'

@Component({
  selector: 'htc-hero-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})

export class HeroBackgroundComponent {
  public block_timeout = 60

  // Move color background on the mouse move
  @HostListener('document:mousemove', ['$event'])
  onmousemove(e: MouseEvent) {
    let centerX = e.pageX - e.view.innerWidth/2
    let centerY = -e.pageY + e.view.innerHeight/2

    let normalizedX = this.constrain(centerX, -e.view.innerWidth/2, e.view.innerWidth/2, -1, 1)
    let normalizedY = this.constrain(centerY, -e.view.innerHeight/2, e.view.innerHeight/2, -1, 1)

    let items = document.querySelectorAll('.htc-color-bg__item')
    for (let i = 0; i < items.length; i++) {
      let block: any = items[i]

      let offsetx = Number.parseInt(block.dataset.offsetx)
      let offsety = Number.parseInt(block.dataset.offsety)

      let translateX = this.map(normalizedX, -1, 1, -offsetx, offsetx)
      let translateY = this.map(normalizedY, -1, 1, -offsety, offsety)

      block.style.transform = `translate3d(${translateX}px, ${translateY}px, 0)`
    }
  }

  @HostListener('window:resize', ['$event'])
  omResize(e: WindowEventMap) {
    let width = this.getWindowWidth()/28
    let height = this.getHexHeight(width)

    let items = document.querySelectorAll('.htc-color-bg__item')
    for (let i = 0; i < items.length; i++) {
      let blocks = items[i].children
      for (let j = 0; j < blocks.length; j++) {
        let child :any = blocks[j]
        let x = child.dataset.x
        let y = child.dataset.y
        let top = this.getTop(Number.parseInt(x), Number.parseInt(y), height)
        let left = this.getLeft(x, width)

        child.style.width = width + 'px'
        child.style.height = height + 'px'
        child.style.top = top + 'px'
        child.style.left = left + 'px'
      }
    }
  }

  constrain(coordinate, minValue, maxValue, convertedMinValue, convertedMaxValue) {
    // Constraint for X: (centerX, leftBorder, rightBorder, desiredLeft, desiredRight)
    let offset = this.map(coordinate, minValue, maxValue, convertedMinValue, convertedMaxValue)

    return Math.max(Math.min(offset, 1), -1)
  }

  map(coordinate, minValue, maxValue, convertedMinValue, convertedMaxValue) {
    return ((coordinate - minValue)/(maxValue-minValue)) * (convertedMaxValue - convertedMinValue) + convertedMinValue
  }

  ngAfterViewInit() {
    this.colorBgAnimation()
    this.svgAnimations()
  }

  private svgAnimations() {
    // Animate big hexagons
    const svgUnderPath = document.querySelectorAll('.htc-under-bg path');
    anime({
      targets: svgUnderPath,
      loop: true,
      direction: 'alternate',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 1250,
      delay: (el, i) => { return i * 250 }
    });

    // Animate entrance of the small hexagons
    const svgTopPath = document.querySelectorAll('.htc-top-bg path');
    anime({
      targets: svgTopPath,
      loop: false,
      direction: 'alternate',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 400,
      delay: (el, i) => { return i * 120 }
    });
  }

  private colorBgAnimation(update: number = 1): void {
    let bg = document.getElementById('htc-color-bg')
    let index: number = 0
    let delay: number = 0

    color_bg.forEach((element) => {
      let id = 'htc-color-bg__item-' + index
      window.setTimeout(() => {
        let item: HTMLDivElement = document.createElement('div')
        item.id = id
        item.className = 'htc-color-bg__item'
        item.dataset.offsetx = this.getRandomInt(5, 25)
        item.dataset.offsety = this.getRandomInt(5, 30)
        bg.appendChild(item)
        this.createBlocks(element.coordinates, id, update)
      }, delay)
      delay += this.block_timeout * element.coordinates.length
      index++
    })
  }

  public createBlocks(coordinates, id, update: number): void {
    let width = this.getWindowWidth()/28
    let height = this.getHexHeight(width)
    let base = document.getElementById(id)
    let index: number = 0
    coordinates.forEach((coordinate) => {
      let x = coordinate[0]
      let y = coordinate[1]
      let top = this.getTop(x, y, height)
      let left = this.getLeft(x, width)
      window.setTimeout(() => {
        let block = document.createElement('div')
        block.style.width = width + 'px'
        block.style.height = height + 'px'
        block.style.top = top + 'px'
        block.style.left = left + 'px'
        block.className = 'color-hexagon-animation'
        block.dataset.x = x
        block.dataset.y = y
        base.appendChild(block)
      }, this.block_timeout * index * update)

      index++
    })
  }

  private getTop(x, y, height) {
    return (y + x % 2) * (height + 2) - (height/2 + 1) * (x % 2)
  }

  private getLeft(x, width) {
    return (0.75 * width + 2) * x
  }

  private getHexHeight(width) {
    return width*Math.pow(3, 0.5)/2
  }

  private getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
  }

  private getWindowWidth(): number {
    return window.innerWidth > 1300 ? window.innerWidth : 1300
  }
}
