import { Component, HostListener } from '@angular/core'

@Component({
  selector: 'htc-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})

export class HeroComponent {
  public block_timeout = 100

  ngAfterViewInit() {

  }
}
