export const color_bg = [
  {
    coordinates: [
      [0, 0],
      [0, 1],
      [1, 0],
      [2, 1],
      [2, 2],
      [3, 2],
      [4, 2],
      [4, 3],
      [4, 4],
      [5, 3],
      [6, 3],
      [6, 4]
    ]
  }, 
  {
    coordinates: [
      [0, 7], 
      [1, 6],
      [2, 7],
      [3, 6],
      [3, 5],
      [4, 6],
      [5, 5]
    ]
  },
  {
    coordinates: [
      [7, 5], 
      [8, 6],
      [8, 5],
      [9, 4],
      [10, 4],
      [10, 5],
      [11, 4],
      [11, 5]
    ]
  }, 
  {
    coordinates: [
      [4, 8],
      [4, 9], 
      [5, 9],
      [6, 9],
      [6, 8],
      [7, 7],
      [8, 8],
      [6, 10],
      [7, 10],
      [8, 10]
    ]
  }, 
  {
    coordinates: [
      [11, 9],
      [11, 8],
      [11, 7],
      [12, 8],
      [13, 8],
      [13, 9],
      [13, 7],
      [14, 7], 
      [14, 6]
    ]
  }, 
  {
    coordinates: [
      [16, 8],
      [16, 7],
      [17, 7], 
      [17, 8], 
      [17, 9], 
      [16, 10], 
      [15, 10]
    ] 
  }, 
  {
    coordinates: [
      [19, 7],
      [20, 8],
      [21, 7], 
      [21, 8], 
      [22, 10], 
      [22, 9], 
      [23, 9]
    ]
  }, 
  {
    coordinates: [
      [27, 7], 
      [26, 8],
      [25, 8],
      [26, 9], 
      [26, 10],
      [25, 10]
    ]
  }, 
  {
    coordinates: [
      [28, 10], 
      [29, 9], 
      [30, 9], 
      [30, 8], 
      [29, 7], 
      [31, 7]
    ]
  }, 
  {
    coordinates: [
      [33, 7], 
      [33, 8], 
      [34, 8], 
      [34, 7], 
      [34, 6], 
      [35, 6], 
      [36, 7],
      [37, 7]
    ]
  }, 
  {
    coordinates: [
      [35, 3], 
      [36, 3], 
      [36, 4], 
      [37, 4], 
      [37, 5]
    ]
  },
  {
    coordinates: [
      [33, 10], 
      [34, 10], 
      [35, 9], 
      [36, 10], 
      [37, 9]
    ]
  }
]