import { Component, OnInit, HostListener } from '@angular/core'
import * as ScrollMagic from 'scrollmagic'

@Component({
  selector: 'htc-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})

export class TeamComponent implements OnInit {
  private img_width: number
  private row_size: number
  private rows: number 
  private wrapper_height: number
  public progress: number
  public team = [
    {
      img: 'Roy',
      name: 'Roy',
      last: 'Hill-Percival',
      links: {
        linkedin: 'https://www.linkedin.com/in/royhp/'
      },
      title: 'Founding Partner',
      skills: 'Architecture, Engineering, Security, iOS, Embedded'
    },
    {
      img: 'Kristina',
      name: 'Kristina',
      last: 'Kononovich',
      links: {
        linkedin: 'https://www.linkedin.com/in/kristina-kononovich/'
      },
      title: 'Founding Partner',
      skills: 'Front-End, Full-Stack, Engineering, Management'
    },
    {
      img: 'Wes',
      name: 'Wesly',
      last: 'Lorenzini',
      links: {
        linkedin: 'https://www.linkedin.com/in/wesleylorenzini/'
      },
      title: 'Associate',
      skills: 'iOS, Platform Engineering, Management'
    },
    {
      img: 'Maya',
      name: 'Maya',
      last: 'Vera',
      links: {
        linkedin: 'https://www.linkedin.com/in/mayavera/'
      },
      title: 'Associate',
      skills: 'Full-Stack, Platform Engineering, Embedded'
    },
    {
      img: 'James',
      name: 'James',
      last: 'Manlapid',
      links: {
        linkedin: 'https://linkedin.com/jmanlapid/'
      },
      title: 'Associate',
      skills: 'Front-End, Full-Stack'
    },
    {
      img: 'Michael',
      name: 'Michael',
      last: 'Selsky',
      links: {
        linkedin: 'https://www.linkedin.com/in/michaelselsky/'
      },
      title: 'Associate',
      skills: ''
    },
    {
      img: 'Saeed',
      name: 'Saeed',
      last: 'Abbassi',
      links: {
        linkedin: 'https://www.linkedin.com/in/saeedabbassi/'
      },
      title: 'Associate',
      skills: 'Full-Stack, Platform Engineering, Embedded'
    },
    {
      img: 'Nan',
      name: 'Nan Sol',
      last: 'Park',
      links: {
        linkedin: ''
      },
      title: 'Associate',
      skills: ''
    },
    {
      img: 'Edouard',
      name: 'Edouard',
      last: 'Barthelemy',
      links: {
        linkedin: 'https://www.linkedin.com/in/edoubart/'
      },
      title: 'Associate',
      skills: 'iOS, Platform Engineering, Management'
    },
    {
      img: 'Dondrey',
      name: 'Dondrey',
      last: 'Taylor',
      links: {
        linkedin: 'https://www.linkedin.com/in/dondreytaylor/'
      },
      title: 'Associate',
      skills: 'Front-End, Full-Stack'
    },
    {
      img: 'Arjun',
      name: 'Arjun',
      last: 'Bhatnagar',
      links: {
        linkedin: 'https://www.linkedin.com/in/arjun-bhatnagar-08a91a39/'
      },
      title: 'Associate',
      skills: 'Front-End, Full-Stack'
    },
    {
      img: 'Flo',
      name: 'Florencio',
      last: 'Marin',
      links: {
        linkedin: 'https://www.linkedin.com/in/florencio-marin-70274a2a/'
      },
      title: 'Associate',
      skills: 'Front-End, Full-Stack'
    }
  ]

  @HostListener('window:resize', ['$event'])
  omResize(e: WindowEventMap) {
    this.setImageSize()
    this.setRowSize()
    this.setWrapper()
    this.setupGrid(1)
  }

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setImageSize()
    this.setRowSize()
    this.setWrapper()
    this.setAnimation()
  }

  private setupGrid(progress: number) {
    let members = document.getElementsByClassName('htc-team-list__member')
    for (let i = 0; i < members.length; i++) {
      let member :any = members[i]
      let y = 0
      let x = 0
      let z_index = 1

      if (i%2 == 1) {
        y = 50
        z_index += 1
      }

      if (i + 1 > this.row_size) {
        let number = (i - i % this.row_size)/this.row_size
        x = -75 * number * this.row_size
        y += 100 * number
        z_index += number

        if (this.row_size % 2 == 1) {
          if (number % 2 == 1) {
            if (i%2 == 1) {
              y -= 50
            } else {
              z_index += 2
              y += 50
            }
          } else {
            z_index += 1
          }
        }
      }

      let item_progress = progress + 1/(3*this.team.length) * (this.team.length - i)

      if (item_progress > 1) {
        item_progress = 1
        member.classList.add('htc-team-list__member--fixed')
      }

      member.style.transform = `translate(${(x - 25 * i)*item_progress}%, ${y*item_progress}%)`
      member.style['z-index'] = z_index
    }
  }

  private setRowSize() {
    let width = window.innerWidth > 1072 ? 1072 : window.innerWidth
    let amount = (width - width % this.img_width)/this.img_width
    this.row_size = amount % 3 > 0 ? (amount - amount % 3)/3 : (amount - 3)/3
    
    let rows = (this.team.length - this.team.length % this.row_size)/this.row_size
    rows = this.team.length % this.row_size == 0 ? rows : rows + 1
    this.rows = this.team.length % this.row_size != 1 ? rows + 0.5 : rows
  }

  private setImageSize() {
    if (window.innerWidth <= 420) {
      this.img_width = 50
    } else {
      this.img_width = 60
    }
  }

  private setWrapper() {
    this.wrapper_height = Math.ceil(this.rows * this.img_width * 4 * Math.pow(3, 0.5)/2)
    let wrapper = document.getElementById('htc-team-list')
    wrapper.style.width = `${(this.row_size * 3 + 1) * this.img_width}px`
    wrapper.style.height = `${this.wrapper_height}px`
  }

  private setAnimation() {
    let controller = new ScrollMagic.Controller()
    let options = {
      duration: this.wrapper_height+45,
      triggerElement: '#htc-team-trigger',
      triggerHook: 'onEnter'
    }
    
    new ScrollMagic.Scene(options)
      .addTo(controller)
      .on('progress', (event) => {
        this.progress = event.progress
        this.setupGrid(event.progress)
      })
  }
}
