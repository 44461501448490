import { Component, OnInit } from '@angular/core'
import * as ScrollMagic from 'scrollmagic'

@Component({
  selector: 'htc-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  public services = [
    {
      label: "Design",
      description: "Comprehensive Product/Platform Design, Architecture, and Implementation."
    },
    {
      label: "Development",
      description: "From new projects to maintenance and audits, we can build standalone or integrate with your existing platform or team."
    },
    {
      label: "Integration",
      description: "We connect and consolidate any existing platform or software solution, improving reliability and reducing components to drive down cost."
    },
    {
      label: "Marketing",
      description: "Marketing strategy and implementation to drive growth, public image, and ROAS across multiple channels."
    },
    {
      label: "Infrastructure",
      description: "Whether it's on-premise, AWS, Azure, or Rackspace - we build custom solutions, and maintain existing stacks."
    },
    {
      label: "Process Engineering",
      description: "Stream-line business functions, identify and eliminate waste and inefficiencies."
    },
    {
      label: "Other",
      description: "Need something special? Let us know, we would love to hear about it."
    }
  ]

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let wrapper = document.getElementById('htc-services')
    setTimeout(() => {
      let controller = new ScrollMagic.Controller()
      let options = {
        duration: wrapper.offsetHeight,
        triggerElement: '#htc-services-trigger',
        triggerHook: 'onEnter'
      }
      let scene = new ScrollMagic.Scene(options)
        .addTo(controller)
        .on('progress', (event) => {
          let services = document.getElementsByClassName('htc-services-list__item')
          for (let i=0; i<services.length; i++) {
            let service: any = services[i]
            service.style.transform = `translateY(${ wrapper.offsetHeight * 0.5 * (this.getTranslate(i, event.progress))}px)`
            service.style.opacity = this.getOpacity(i, event.progress)
          }

        })
    }, 0)
  }

  private getTranslate(index: number, progress: number) {
    let translate = 0.4 + 0.1 * index - progress

    return translate < 0 ? 0 : translate
  }

  private getOpacity(index: number, progress: number) {
    let opacity =  0.6 - index * 0.1 + Math.pow(progress, 4)
    return opacity
  }
}
